export const Footer = (props) => {

  return (
    <div>
      <div id='footer'>
        <div className='container text-center'>
          <p>
            &copy; Ucept LLC {new Date().getFullYear()}
          </p>
        </div>
      </div>
    </div>
  )
}
